const routes = {
  overview: () => "/",
  cover: () => "/cover",
  appendix: () => "/appendix",
  summary: () => "/summary",
  product: (productId: string) =>
    `/product${productId ? `?e=${productId}` : ""}`,
};

export default routes;
