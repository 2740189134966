import { create } from "apisauce";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import localforage from "localforage";

import Config from "config";

// Create `localforage` instance
export const forageStore = localforage.createInstance({
  driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
  name: `${Config.apiDomain}|query|CBH-CMS`,
});

// Non memory cache
// Memory cache is handled by react-query
export const cache = setupCache({
  maxAge: parseInt(process.env.REACT_APP_API_CACHE_AGE as string, 10),
  store: forageStore,
  exclude: {
    filter: (req) => {
      return req.params?._cache !== "1";
    },
  },
});

export const apiStore = cache.store as LocalForage;

// Trigger to clear cache
export const clearCache = async () => {
  await apiStore.clear();
};

// Prepare apisauce requestor instance
const getRequestorInstance = () => {
  const cachedAxiosInstance = axios.create({
    baseURL: Config.apiDomain,
    adapter: cache.adapter,
  });

  //@ts-ignore
  const apisauceInstance = create({ axiosInstance: cachedAxiosInstance });

  return apisauceInstance;
};

const requestor = getRequestorInstance();

export const getTypedRequestor = (type) => {
  return requestor[type];
};
