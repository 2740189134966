import clsx from "clsx";
import RemainingHeightContext from "components/Scaffold/RemainingHeightContext";
import _ from "lodash";
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import SizedTableRow from "./SizedTableRow";
import TableCell from "./TableCell";

export type DataCell = {
  value: ReactNode;
  span?: number;
  tbodyClassName?: string;
  className?: string;
  textClassName?: string;
  component?: ReactNode;
  header?: boolean;
  renderHeaderData?: [DataCell[], number];
  cellKey: number;
  colIndex?: number;
};

type Props = {
  row?: DataCell[];
  col?: Omit<DataCell, "groupedValue">[];
  data: DataCell[][];
  start?: number;
  useOffsetHeight?: boolean;
  onOverflow?: (start: number) => void;
  overflowEven?: number;
  sized?: boolean;
  offset?: number;
  colIndexOffset?: number;
  id?: string;
  noBaseHeader?: boolean;
  useGrouped?: boolean;
  fixed?: boolean;
  tableRef?: React.MutableRefObject<HTMLDivElement | null>;
};

function Table({
  row,
  data: inputData,
  col,
  start,
  useOffsetHeight,
  onOverflow,
  overflowEven,
  sized,
  offset = 0,
  colIndexOffset = 0,
  id,
  noBaseHeader = false,
  useGrouped = false,
  fixed = false,
  tableRef,
}: Props) {
  const heights = useRef<number[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_update, setUpdate] = useState(0);

  const { offsetHeight, wrapperHeight } = useContext(RemainingHeightContext);

  const [extraHiddenIndex, setExtraHiddenIndex] = useState(-1);
  const [overflowed, setOverflowed] = useState(false);

  let maxHeight: number | undefined = wrapperHeight;
  if (useOffsetHeight) {
    maxHeight = (wrapperHeight ?? 0) - (offsetHeight ?? 0) || undefined;
  }

  const smallestStart = useRef(Infinity);
  const onOverflowHandler = useCallback(
    (j: number) => {
      if (j < smallestStart.current) {
        smallestStart.current = j;
        onOverflow?.(j);

        let i = j - 1;
        if (overflowEven && i > 0 && i % overflowEven === 1) {
          i = i - 1;
          setExtraHiddenIndex(i);
        }
        setOverflowed(true);
      }
    },
    [onOverflow, overflowEven]
  );

  // Force update sized calculation table row height
  useEffect(() => {
    if (!sized) {
      return;
    }
    const interval = setInterval(() => {
      setUpdate(new Date().getTime());
    }, 450);
    return () => {
      clearInterval(interval);
    };
  }, [sized]);

  const setHeight = useCallback((h: number, index: number) => {
    heights.current[index] = h;
  }, []);

  const renderHeader = useCallback(
    (
      headerData: Omit<DataCell, "groupedValue">[] | undefined,
      indexOffset?: number
    ) => {
      return headerData ? (
        headerData.map((headRowData, k) => (
          <th
            key={k}
            colSpan={headerData[k].span}
            className={headerData[k].className}
          >
            <div className={headerData[k].textClassName}>
              {headerData[k].colIndex ?? k + (indexOffset ?? 0)}.{" "}
              {headRowData.value}
            </div>
          </th>
        ))
      ) : (
        <></>
      );
    },
    []
  );

  const columnHeader = renderHeader(col, colIndexOffset);

  const data = inputData.filter((s) => !_.isEmpty(s));
  const groupedData = _.entries(_.groupBy(data, (s) => s[0].cellKey));
  const groupedRow = _.groupBy(row, (s) => s.cellKey);

  return (
    <div
      ref={tableRef ? (s) => (tableRef.current = s) : undefined}
      id={id ? `table_${id}` : undefined}
      style={{ maxHeight }}
      className={clsx([
        "c-table overflow-hidden",
        start && start > data.length + offset && "hidden",
      ])}
      aria-label={overflowed ? "overflowed" : undefined}
    >
      <StyledTable
        cellSpacing={2}
        className={fixed ? "table_fixed" : undefined}
      >
        {fixed && (
          <thead>
            <tr>
              {_.range(data?.[0]?.length + (col ? 1 : 0)).map((s) => (
                <th key={s} />
              ))}
            </tr>
          </thead>
        )}
        {!noBaseHeader && col && (
          <SizedTableRow
            index={0}
            invisible={false}
            setHeight={setHeight}
            disabled={!sized}
          >
            {columnHeader}
          </SizedTableRow>
        )}
        {!useGrouped &&
          data.map((rowData, i) => (
            <SizedTableRow
              index={i + 1}
              hidden={start && i < start - 1 ? true : false}
              invisible={
                (maxHeight
                  ? maxHeight <=
                    heights.current
                      .slice(0, i + 2)
                      .reduce((p, c) => p + (c ?? 0), 0)
                  : false) || extraHiddenIndex === i
              }
              onInvisible={onOverflowHandler}
              key={i}
              setHeight={setHeight}
              disabled={!sized}
            >
              <TableCell
                columnHeader={columnHeader}
                row={row?.[i]}
                rowData={rowData}
                renderHeader={renderHeader}
              />
            </SizedTableRow>
          ))}
        {useGrouped &&
          groupedData.map(([cellKey, rowsData], i) => (
            <SizedTableRow
              index={i + 1}
              hidden={start && i < start - 1 ? true : false}
              invisible={
                (maxHeight
                  ? maxHeight <=
                    heights.current
                      .slice(0, i + 2)
                      .reduce((p, c) => p + (c ?? 0), 0)
                  : false) || extraHiddenIndex === i
              }
              onInvisible={onOverflowHandler}
              key={i}
              setHeight={setHeight}
              disabled={!sized}
            >
              {rowsData.map((rowData, r) => (
                <TableCell
                  key={r}
                  columnHeader={columnHeader}
                  row={groupedRow[cellKey]?.[r]}
                  rowData={rowData}
                  renderHeader={renderHeader}
                />
              ))}
            </SizedTableRow>
          ))}
        <tfoot style={{ display: "none" }}>
          <tr>
            <td>
              <div style={{ height: 9999 }} />
            </td>
          </tr>
        </tfoot>
      </StyledTable>
    </div>
  );
}

const StyledTable = styled.table`
  width: 100%;

  &.table_fixed {
    table-layout: fixed;

    thead > tr {
      th:first-child {
        width: 200px;
      }
    }
  }

  tr > td,
  tr > th {
    padding: 1px;
    height: 1px;
    position: relative;
  }

  tr > td > div,
  tr > th > div {
    padding: 3px 5px;
    text-align: center;
    font-size: 12px;
    height: 100%;
    word-break: break-word;
  }

  tbody:nth-child(odd) > tr > td > div,
  th > div {
    background-color: #ffe8ca;
  }

  tbody:nth-child(even) > tr > td > div {
    background-color: #fff4e6;
  }

  th:not(.clean):not(.plain) > div,
  tr > td.row:not(.clean):not(.plain) > div {
    background-color: #ffc002;
    font-weight: 700;
  }

  tr > td > div.sublevel {
    padding-left: 20px;
    font-weight: 400 !important;
  }

  tr > td.plain,
  tr > td.plain > div {
    background-color: transparent !important;
  }

  td.row-hidden,
  td.row-hidden ~ td {
    display: none;
  }
`;

export default Table;
