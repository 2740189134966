import { getModuleVersionDetail } from "api/module";
import clsx from "clsx";
import ImageUid from "components/Image/ImageUid";
import _ from "lodash";
import qs from "query-string";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import useLang from "utils/hooks/useLang";
import { safeJSONParse } from "utils/transform/json";
import tuple from "utils/types/tuple";
import DataDisplayContext from "./DataDisplay/DataDisplayContext";
import { captionTranslate } from "utils/transform/multilang";
import { find, get, isNumber } from "lodash-es";
import useTaskProductData from "pages/ProductPage/useTaskProductData";

type Props = {
  polyModelVid: string | undefined;
  replies: any[] | undefined;
  useLoopImageQuestionFirst?: boolean;
};

type ImageData = {
  uid: string;
  answerId: string;
  field: any;
  index: number;
  order: number;
};

const getPathData = (data: any, path: string) => {
  const pathArr = path.split(".");
  let value = data;
  pathArr.forEach((k) => {
    value = value?.[k];
  });
  return value;
};

const useProductQuestionDetailImage = ({
  polyModelVid,
  replies,
  useLoopImageQuestionFirst = false,
}: Props) => {
  const [, isLang] = useLang();

  const { data: polyModel } = useQuery(
    tuple(["getModuleVersionDetail", "poly_model", polyModelVid as string]),
    getModuleVersionDetail,
    { enabled: !!polyModelVid }
  );

  const joinedData = useTaskProductData();

  // Get all answers
  const answersData = replies;

  const answersDataValue = answersData?.map((a) => {
    const value = safeJSONParse(a.data);
    return value;
  });

  const model: any[] = [];
  const polyModelModel = polyModel?.model;
  polyModelModel?.forEach((s, i) => {
    model.push({
      ...s,
      path: `${i}`,
      children: undefined,
    });

    // INFO: max support three level
    if (s.children) {
      s.children.forEach((k, j) => {
        model.push({
          ...k,
          path: `${i}.${j}`,
          use_image_supporting: s.enable_image_supporting,
        });

        if (k.children) {
          k.children.forEach((p, h) => {
            model.push({
              ...p,
              path: `${i}.${j}.${h}`,
              use_image_supporting: k.enable_image_supporting,
            });
          });
        }
      });
    }
  });

  const imageOnlyModel = model
    .filter((s) => s.type === "image")
    .map((s) => {
      return {
        ...s,
        values: answersDataValue?.map((k) => getPathData(k, s.path)),
      };
    });

  const imagesData = useMemo(() => {
    const questionsImgData = imageOnlyModel.map((e) => {
      if (!e.values || e.values.length === 0) {
        return [];
      }

      return (e.values as string[][])
        .map((v: string[], l: number) => {
          if (!v || !_.isArray(v) || _.isEmpty(v)) {
            return [];
          }
          const answerId = answersData?.[l]?.id;
          if (!answerId) {
            return [];
          }
          // Per machine
          return v.map((s, j) => ({
            uid: s,
            answerId,
            field: e,
            index: l,
            order: j,
          }));
        })
        .reverse();
    });

    if (useLoopImageQuestionFirst) {
      return questionsImgData.flat(2);
    }

    const unFlatImgData: ImageData[][] = [];
    // Per machine
    questionsImgData[0]?.forEach((s, i) => {
      // Machine title
      if (isNumber(s?.[0]?.index)) {
        const machineData = answersData?.[s[0].index]?.equipment;
        if (machineData) {
          const machine = find(joinedData, (e) => e.id === machineData);
          if (machine?.custom_identifier) {
            unFlatImgData.push({
              title: (
                <h4 className="leading-tight text-left font-bold lg-title text-[18px] text-[#01328A] pt-2">
                  {`${isLang("zh-Hant") ? "圖片附件 " : ""}${
                    isLang("en") ? "Image Attachment " : ""
                  }: `}
                  {machine.custom_identifier ? captionTranslate(machine.custom_identifier): ""}
                </h4>
              ),
            } as any);
          }
        }
      }

      // Per question
      questionsImgData.forEach((_k, j) => {
        unFlatImgData.push(questionsImgData[j][i]);
      });
    });
    return unFlatImgData.flat();
  }, [
    answersData,
    imageOnlyModel,
    isLang,
    joinedData,
    useLoopImageQuestionFirst,
  ]);

  const lastUpdatedImagesLength = useRef<number>(0);
  const { setValue } = useContext(DataDisplayContext);
  useEffect(() => {
    if (imagesData.length <= lastUpdatedImagesLength.current) {
      return;
    }
    lastUpdatedImagesLength.current = imagesData.length;
    let count = 0;
    imagesData.forEach((s, index) => {
      if (!s.uid) {
        return;
      }
      count += 1;
      setValue(
        s.uid,
        `${isLang("en") ? "Fig." : ""}${
          isLang("zh-Hant") ? "圖像" : ""
        } #${count}`
      );
    });
  }, [imagesData, isLang, setValue]);

  const url = qs.parse(window.location.search);
  const isA3 = url.a3 === "1";

  const handler = useCallback(() => {
    const base = isA3 ? 3 : 2;

    let chunkedImagesRows: typeof imagesData[] = [[]];
    let i = 0;
    let chunkCount = 0;
    imagesData.forEach((s) => {
      if ((s as any).title || chunkCount === base - 1) {
        i += 1;
        chunkCount = (s as any).title ? -1 : 0;
      } else {
        chunkCount += 1;
      }
      if (!chunkedImagesRows[i]) {
        chunkedImagesRows[i] = [];
      }
      chunkedImagesRows[i].push(s);
    });
    // At least have one image inside
    chunkedImagesRows = chunkedImagesRows.filter((s) => s.some((k) => !!k.uid));

    const renderImage = (i: number, data?: ImageData) => {
      if (!data) {
        return <></>;
      }

      const { uid, answerId, field, index, order } = data;

      const ansData = safeJSONParse(answersData?.[index]?.data);

      const fieldData = get(
        ansData,
        (field.path.split(".") as string[]).slice(0, -1).join(".")
      );
      const imageDescriptions = !fieldData?.[4]
        ? fieldData?.[3] || field.preset_image_descriptions
        : null;

      return (
        <div key={uid} className="mt-3">
          <p className="text-xs">
            {`${isLang("en") ? "Fig." : ""}${
              isLang("zh-Hant") ? "圖像" : ""
            } #${i + 1}`}
          </p>
          <StyledImageWrapper>
            <ImageUid
              fileId={uid}
              module="poly_data"
              id={answerId}
              key={uid}
              showFileInfo
            />
            {!!imageDescriptions?.[order] && (
              <div className="text-[14px]">
                {captionTranslate(imageDescriptions?.[order])}
              </div>
            )}
          </StyledImageWrapper>
        </div>
      );
    };

    return chunkedImagesRows.map((imageRow, j) => {
      const hasTitle = imageRow.some((image) => !!(image as any).title);
      return (
        <div
          className={clsx(["flex flex-wrap -mx-5", hasTitle && "page-break"])}
        >
          {imageRow.map((image, k) => {
            if (!!(image as any).title) {
              return (
                <ImageWrap className="px-5">{(image as any).title}</ImageWrap>
              );
            }
            return (
              <div
                className={clsx([
                  "px-5",
                  base === 2 && "basis-1/2",
                  base === 3 && "basis-1/3",
                ])}
              >
                {renderImage(j * base + k + (hasTitle ? -1 : 0), image)}
              </div>
            );
          })}
        </div>
      );
    });
  }, [answersData, imagesData, isA3, isLang]);

  return handler;
};

const StyledImageWrapper = styled.div`
  max-height: 320px;

  & > img {
    max-height: 320px;
    object-fit: contain;
    object-position: top left;
  }
`;

const ImageWrap = styled.div`
  flex-shrink: 0 !important;
  flex-basis: 100% !important;
`;

export default useProductQuestionDetailImage;
