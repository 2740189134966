import { getModuleValue } from "api/module";
import React, { useCallback, useState } from "react";
import { useQuery } from "react-query";
import tuple from "utils/types/tuple";
import qs from "query-string";

import routes from "config/routes";
import { isEmpty } from "lodash-es";
import { captionTranslate } from "utils/transform/multilang";
import usePreview from "utils/hooks/usePreview";
import useModuleListing from "containers/useModuleListing";
import _ from "lodash";
import ErrorMessage from "components/ErrorMessage";
import RadioInput from "components/RadioInput";
import ProductLink from "./ProductLink";
import useScroll from "utils/hooks/useScroll";

type Props = {};

const wrapperStyle = {
  backgroundColor: "#aaa",
  minHeight: "100vh",
  padding: 20,
  fontSize: 16,
};

const OverviewPage = (props: Props) => {
  const url = qs.parse(window.location.search);
  const [token, setToken] = useState<string>(url.t as string);
  const [taskId, setTaskId] = useState<string>(url.p as string);

  const isPreview = usePreview();

  const [pageSize, setPageSize] = useState((url.size as string) ?? "a4");
  const [lang, setLang] = useState((url.lang ?? "en") as string);

  useScroll();

  const { data, error } = useQuery(
    tuple(["getModuleValue", "task", taskId]),
    getModuleValue,
    {
      enabled: !!taskId,
    }
  );

  const productIds = data?.value?.question_sheet
    ? Object.values(data?.value?.question_sheet)?.map((s: any) => s.product)
    : undefined;

  const { data: productData } = useModuleListing(
    "product",
    productIds,
    !!productIds && isPreview
  );

  const setTokenUrl = useCallback(() => {
    if (token) {
      const search = qs.stringify({
        ...url,
        t: token,
        p: taskId,
      });
      window.location.replace(window.location.origin + `?` + search);
    }
  }, [taskId, token, url]);

  if (!taskId || !token || error) {
    return (
      <div style={wrapperStyle}>
        <ErrorMessage
          error={
            "This preview link is invalid or has been expired. 此預覽鏈接無效或已過期。"
          }
        />
        <ErrorMessage error={error} />
      </div>
    );
  }

  const param = {
    lang: lang,
    // Pass a, use decrypted token from session storage
    ...(isPreview ? { preview: "1" } : {}),
  };

  const links = data ? (
    <>
      <OverviewLink
        path={routes.cover()}
        taskId={taskId}
        token={token}
        param={param}
        caption="Cover 覆蓋"
      />
      <OverviewLink
        path={routes.summary()}
        taskId={taskId}
        token={token}
        param={param}
        caption="Summary 概括"
      />
      {productIds?.map((p) => (
        <ProductLink key={p} productId={p} taskId={taskId}>
          <OverviewLink
            path={routes.product(p)}
            taskId={taskId}
            token={token}
            param={param}
            isA3={pageSize === "a3"}
            caption={
              "Product 產品 " +
              (isPreview
                ? captionTranslate(
                    _.find(productData?.value as any, (s: any) => s.id === p)
                      ?.name
                  )
                : p)
            }
          />
        </ProductLink>
      ))}
      {!isEmpty(data?.value?.appendix) && (
        <OverviewLink
          path={routes.appendix()}
          taskId={taskId}
          token={token}
          param={param}
          caption="Appendix 概括"
        />
      )}
    </>
  ) : (
    <div>Loading 正在加載 ... </div>
  );

  return (
    <div style={wrapperStyle}>
      <h1 style={{ marginBottom: 10, fontWeight: 700 }}>
        Task Report Preview 任務報告預覽
      </h1>
      <h2 style={{ marginBottom: 20 }}>
        {captionTranslate(data?.value?.name)}
      </h2>
      <>
        <RadioInput
          value={pageSize}
          options={{
            a3: "A3",
            a4: "A4",
          }}
          className="mb-3"
          onChange={setPageSize}
        />
        <RadioInput
          value={lang}
          options={{
            bi: "中英 Bilingual",
            en: "English",
            "zh-Hant": "中文",
          }}
          className="mb-6"
          onChange={setLang}
        />
      </>
      {!isPreview && (
        <div style={{ maxWidth: 600 }}>
          <div style={{ marginBottom: 20 }}>
            <h4>Task ID:</h4>
            <input
              value={taskId}
              onChange={(e) => setTaskId(e.target.value)}
              style={{ width: "100%" }}
            />
          </div>
          <div style={{ marginBottom: 20 }}>
            <h4>Bearer Token:</h4>
            <textarea
              value={token}
              onChange={(e) => setToken(e.target.value)}
              style={{ width: "100%", minHeight: 150 }}
            />
            <button onClick={setTokenUrl}>Set Token</button>
          </div>
          <div style={{ marginBottom: 20 }}>{JSON.stringify({ param })}</div>
        </div>
      )}
      <div style={{ maxWidth: 600 }} id="links">
        {links}
      </div>
      <div className="mt-6">
        *{" "}
        <span className="text-[#a00] font-bold">
          DO <u>NOT</u> SHARE ANY LINK OF THIS PREVIEW
        </span>
        , as it contains sensitive information
      </div>
    </div>
  );
};

function OverviewLink({
  path,
  taskId,
  token,
  param,
  isA3,
  caption,
}: {
  path: string;
  taskId: string;
  token: string;
  param: Record<string, any>;
  isA3?: boolean;
  caption: string;
}) {
  const url = qs.parseUrl(path);
  const href = qs.stringifyUrl({
    url: url.url,
    query: {
      ...url.query,
      ...param,
      ...(isA3 ? { a3: "1" } : {}),
      p: taskId,
      t: token,
    },
  });

  const isPreview = usePreview();

  return (
    <a
      target="_blank"
      href={href}
      style={{
        display: "block",
        marginBottom: 10,
        padding: 10,
        fontSize: 15,
        backgroundColor: "#fff",
        color: "#000",
      }}
      className="link"
      rel="noreferrer"
    >
      {caption}
      {isPreview ? "" : ` - ${path}`}
    </a>
  );
}

export default OverviewPage;
