import React from "react";
import FooterDetail from "./FooterDetail";
import qs from "query-string";
import { isArray } from "lodash-es";

function Footer() {
  const url = qs.parse(window.location.search);

  const pageNumbers = isArray(url.page)
    ? url.page[url.page.length - 1]
    : String(url.page ?? "");

  return (
    <div className="flex items-center px-10 relative" style={{ top: -1 }}>
      <FooterDetail pageNumbers={pageNumbers} />
    </div>
  );
}

export default Footer;
