import Title from "components/Title";
import React from "react";

interface Props {
  html: string;
  asrisk?: boolean;
  remarksTitle?: boolean;
}

function TextareaDisplay({ html, asrisk, remarksTitle = false }: Props) {
  return (
    <div>
      {remarksTitle && (
        <Title
          title="Remarks"
          zhTitle="備註"
          prefix={
            asrisk ? (
              <span className="text-xs ml-1">
                (<span className="text-[#E61E25]">*</span>)
              </span>
            ) : undefined
          }
        />
      )}
      {!!html && (
        <div
          className="p-2 bg-[#F0F0F0] text-xs leading-tight"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      )}
    </div>
  );
}

export default TextareaDisplay;
