import { getModuleValue } from "api/module";

import { useQuery } from "react-query";
import tuple from "utils/types/tuple";
import qs from 'query-string';

function useTaskDetail() {
  const url = qs.parse(window.location.search);

  return useQuery(
    tuple(["getModuleValue", "task", url.p as string]),
    getModuleValue
  );
}

export default useTaskDetail;
