/* eslint-disable eqeqeq */
import Footer from "components/Footer";
import Header from "components/Header";
import PageSizeContent from "components/PageSizeContent";
import Scaffold from "components/Scaffold";
import React, { useState } from "react";

import useTaskProductData from "./useTaskProductData";
import useModuleDetail from "containers/useModuleDetail";
import TextareaDisplay from "components/TextareaDisplay";
import useTaskDetail from "containers/useTaskDetail";
import qs from "query-string";
import { find } from "lodash-es";
import ProductionGraph from "components/ProductionGraph";
import ProductIdentify from "./ProductIdentify";

import useProductionQuestionDetail from "components/ProductionQuestionDetail/useProductionQuestionDetail";
import useProductQuestionDetailImage from "components/ProductionQuestionDetail/useProductQuestionDetailImage";
import useReplies from "./useReplies";
import _ from "lodash";
import styled from "styled-components";
import { getBothLangContent } from "utils/transform/multilang";

const ProductPage = () => {
  const url = qs.parse(window.location.search);

  const productId = url.e as string;
  const isA3 = url.a3 === "1";
  const { data } = useTaskDetail();
  const joinData = useTaskProductData();

  const [isDefaultOrder, setIsDefaultOrder] = useState(false);

  const { data: productData } = useModuleDetail("product", productId);

  const productOnlyData = joinData?.filter(
    (p) => p.product_data?.id == productId
  );

  const { data: repliesData } = useReplies(data?.id);
  const repliesArr = repliesData?.value;

  const equipmentIds = (productOnlyData ?? []).map((s) => String(s.id));
  const replies = repliesArr?.filter(
    (s) => equipmentIds.indexOf(String(s.equipment)) !== -1
  );
  let repliesWithProductData = replies?.map((s) => ({
    ...s,
    equipmentData: s.equipment
      ? find(productOnlyData, (k) => k.id === s.equipment)
      : undefined,
  })) as any[];

  // Order by equipments order
  if (data?.value?.equipments_order) {
    repliesWithProductData = _.sortBy(repliesWithProductData, (s) => {
      return s.equipmentData?.id
        ? data.value.equipments_order.indexOf(s.equipmentData.id)
        : -1;
    });
  }

  // Find product setting
  const setting = _.find(data?.value?.remarks, (s) => s.product === productId);
  const remarksBreaker = getBothLangContent(setting?.content)?.replace(
    /\n\n|\n/g,
    ""
  );

  const polyModelVid =
    _.find(data?.value?.question_sheet, (s) => s.product == productId)
      ?.vid_lock ||
    repliesWithProductData?.filter((s) => !!s.poly_model)?.[0]?.poly_model;

  const renderProductQuestionDetailImage = useProductQuestionDetailImage({
    polyModelVid,
    replies: repliesWithProductData,
    useLoopImageQuestionFirst: setting?.use_loop_image_question_first,
  });

  const renderProductionQuestionDetail = useProductionQuestionDetail({
    polyModelVid,
    replies: repliesWithProductData,
  });

  const graphs = data?.value?.graph?.filter((s) => s.product === productId);

  return (
    <PageSizeContent
      className={`page_product product_${productId}`}
      size={isA3 ? "a3" : "a4"}
      horizontal={isA3 ? true : false}
    >
      <StyledScaffold
        header={
          <Header
            title="MAINTENANCE INDIVIDUAL PRODUCT REPORT"
            zhTitle="維修保養個別產品報告"
            subTitle={productData?.value?.name?.["en"]}
            zhSubTitle={productData?.value?.name?.["zh-Hant"]}
          />
        }
        footer={<Footer />}
      >
        <div className="pt-1">
          <ProductIdentify
            productId={productId}
            setIsDefaultOrder={setIsDefaultOrder}
          />
        </div>
        {renderProductionQuestionDetail(
          isDefaultOrder,
          setting?.columns_per_page
        )}
        {renderProductQuestionDetailImage()}
        {graphs?.flatMap((s) => (
          <ProductionGraph
            key={JSON.stringify(s)}
            className="pt-8"
            graph={s}
            replies={repliesWithProductData}
          />
        ))}
        {!!setting?.content && !!remarksBreaker && (
          <div className="mt-4">
            <TextareaDisplay html={remarksBreaker} remarksTitle asrisk />
          </div>
        )}
      </StyledScaffold>
    </PageSizeContent>
  );
};

const StyledScaffold = styled(Scaffold)`
  .c-table tbody.hidden.header {
    display: table-row-group;
    tr:last-child {
      display: none;
    }
  }

  tbody:not(.header) .sub-row-header.row-component .lg-title {
    visibility: hidden;
  }

  .last-ans-row-parent:not(.hidden) + tbody > .sub-row-header > td > div {
    padding-top: 9999px !important;
  }

  tbody.last-ans-row-parent:last-of-type + tfoot {
    display: table-footer-group !important;
  }

  .scaffold > div.hidden + div:not(.hidden) {
    // Margin top for 1st element c table
    & > .c-table {
      margin-top: 8px;
    }

    & > .c-table .c-table_title {
      padding-top: 0px !important;
    }
  }
`;

export default ProductPage;
