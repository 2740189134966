import React from "react";

import Footer from "components/Footer";
import Header from "components/Header";
import PageSizeContent from "components/PageSizeContent";
import Scaffold from "components/Scaffold";
import useTaskDetail from "containers/useTaskDetail";
// import useModuleDetail from "containers/useModuleDetail";
import Title from "components/Title";

type Props = {};

const AppendixPage = (props: Props) => {
  const { data } = useTaskDetail();

  // const productId = "1";
  // const { data: productData } = useModuleDetail("product", productId);

  return (
    <PageSizeContent size="a4" className="page_appendix">
      <Scaffold
        header={
          <Header
            title="MAINTENANCE REPORT"
            zhTitle="維修保養報告"
            subTitle={undefined}
            zhSubTitle={undefined}
            // subTitle={productData?.value?.name?.["en"]}
            // zhSubTitle={productData?.value?.name?.["zh-Hant"]}
          />
        }
        footer={<Footer />}
      >
        {data?.value?.appendix?.map((item) => (
          <div>
            <Title
              title={item?.caption?.en}
              zhTitle={item?.caption?.["zh-Hant"]}
              size="md"
              bar
            />
            <div
              className="mt-1 mb-6 text-xs"
              dangerouslySetInnerHTML={{ __html: item?.content || "" }}
            />
          </div>
        ))}
      </Scaffold>
    </PageSizeContent>
  );
};

export default AppendixPage;
