import qs from "query-string";

const useLang = () => {
  const search = qs.parse(window.location.search);
  const langKey = (search.lang ?? "en") as string;

  const isLang = (key: string) => {
    return langKey === "bi" || langKey === key;
  };

  const finalLangKey = langKey === "bi" ? "zh-Hant" : langKey;
  return [finalLangKey, isLang, langKey === "bi"] as const;
};

export default useLang;
