/* eslint-disable eqeqeq */
import Table from "components/Table";
import Title from "components/Title";
import React from "react";
import { groupBy } from "lodash-es";
import useTaskProductData from "pages/ProductPage/useTaskProductData";
import ProductLegend from "components/Table/ProductLegend";
import IconCheck from "components/Icon/IconCheck";
import { captionTranslate } from "utils/transform/multilang";
import useTaskDetail from "containers/useTaskDetail";
import _ from "lodash";
import useReplies from "pages/ProductPage/useReplies";
import useLang from "utils/hooks/useLang";
import useRenderTable from "components/Table/useRenderTable";
import { isDescending } from "pages/ProductPage/ProductIdentify";

function useSummaryProduct() {
  const { data } = useTaskDetail();
  const joinData = useTaskProductData();

  const [lang, , isBi] = useLang();

  const { data: repliesData } = useReplies(data?.id);
  const replies = repliesData?.value;

  const renderTable = useRenderTable();

  if (!joinData) {
    return [];
  }

  const groupedData = groupBy(joinData, (s) => s.product_data?.id);

  const items = Object.entries(groupedData).flatMap(([product_id, s]) => {
    return _.chunk(s, data?.value?.summary_product_per_row ?? 6);
  });

  const tableData = items.map((inputItem: any) => {
    const itemId = inputItem.map((s) => Number(s.id));
    const item = isDescending(itemId)
      ? _.clone(inputItem).reverse()
      : inputItem;

    const snData = item.map((k) => ({
      value: k.custom_identifier || `#${k.id}`,
    }));
    const ansData = item.map((k) => ({
      value: (() => {
        if (!replies || !k) {
          return "";
        }
        const ans = _.find(replies, (s) => s.equipment == k.id);
        if (!ans) {
          return "O/S";
        }
        if (ans?.equipment_status === "abnormal") {
          return "X";
        }
        if (ans?.equipment_status === "repaired") {
          return "O";
        }
        return <IconCheck />;
      })(),
    }));
    return {
      data: [snData, ansData],
      row: [
        {
          value: isBi ? "No. 編號" : lang === "en" ? "No." : "編號",
          className: "clean font-bold",
        },
        { value: captionTranslate(item?.[0]?.product_data?.name) || "-" },
      ],
    };
  });

  return [
    <div className="flex justify-between mb-1" key="title">
      <Title title="MAINTENANCE ITEMS" zhTitle="保養項目" bar size="md" />
      <ProductLegend className="pl-2" />
    </div>,
    ...renderTable((props) => (
      <Table
        key={JSON.stringify(props)}
        data={tableData.flatMap((s) => s.data)}
        row={tableData.flatMap((s) => s.row)}
        overflowEven={2}
        fixed
        {...props}
      />
    )),
  ];
}

export default useSummaryProduct;
