import useModuleDetail from "containers/useModuleDetail";
import useTaskDetail from "containers/useTaskDetail";
import React from "react";
import { captionTranslate } from "utils/transform/multilang";
import stripRichText from "utils/transform/stripRichText";

const FooterDetail = () => {
  const { data: taskData } = useTaskDetail();
  const { data } = useModuleDetail("ies_company", taskData?.value.ies_company);

  const address = stripRichText(
    [
      captionTranslate(data?.value?.address?.address_line_1, undefined, ""),
      captionTranslate(data?.value?.address?.address_line_2, undefined, ""),
      captionTranslate(data?.value?.address?.address_line_3, undefined, ""),
    ]
      .filter((s) => !!s)
      .join(" ")
  );

  return (
    <div className="px-[50px] relative">
      <img
        src="/images/footer.png"
        className="absolute bottom-0 left-0 right-0 opacity-40 h-[250px] w-full object-cover"
        alt="footer"
      />
      <hr className="border-[#6E95C2] opacity-40 w-[700px] relative z-10" />
      <div className="flex items-center h-[60px] relative z-10">
        <div className="mr-4 w-[60x]">{WhiteIcon}</div>
        <div className="text-white mr-4 mb-[10px]">
          <b className="text-[10px] leading-tight m-0">
            {captionTranslate(data?.value?.name)}
          </b>
          <p className="text-[9px] leading-tight m-0 mt-[1px]">
            {data?.value?.email}
          </p>
        </div>
        <div className="text-white w-[230px] text-[9px] leading-loose mr-4">
          {address}
        </div>
        <div className="text-white text-[9px]">
          <div className="flex">
            <b className="pr-1">Tel:</b>
            <p>{data?.value?.phone}</p>
          </div>
          <div className="flex mt-1">
            <b className="pr-1">Fax:</b>
            <p>{data?.value?.fax}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const WhiteIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="67"
    height="33"
    viewBox="0 0 67 33"
    className="w-full h-auto"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_58"
          data-name="Rectangle 58"
          width="67"
          height="50"
          transform="translate(0.006 -0.491)"
          fill="none"
        />
      </clipPath>
    </defs>
    <g id="Group_40" data-name="Group 40" transform="translate(-0.006 0.49)">
      <g
        id="Group_39"
        data-name="Group 39"
        transform="translate(0 0)"
        clipPath="url(#clip-path)"
      >
        <path
          id="Path_70"
          data-name="Path 70"
          d="M6.89.426h-6v31h0a65.676,65.676,0,0,1,6-7Z"
          transform="translate(0.116 0.083)"
          fill="#fff"
        />
        <path
          id="Path_71"
          data-name="Path 71"
          d="M17.188,12.426v-6h14v-6h-21v18a80.348,80.348,0,0,1,9-6Z"
          transform="translate(2.818 0.083)"
          fill="#fff"
        />
        <path
          id="Path_72"
          data-name="Path 72"
          d="M49.991,3.51h0a14.864,14.864,0,0,0-10-4,10.654,10.654,0,0,0-10,7c5.66-1.509,11.865-3,18-3,.672,0,1.332-.019,2,0"
          transform="translate(8.015 -0.001)"
          fill="#fff"
        />
        <path
          id="Path_73"
          data-name="Path 73"
          d="M6.826,19.405a65.8,65.8,0,0,0-6,7h6Z"
          transform="translate(0.18 5.104)"
          fill="#fff"
        />
        <path
          id="Path_74"
          data-name="Path 74"
          d="M6.826,19.405a65.8,65.8,0,0,0-6,7h6Z"
          transform="translate(0.18 5.104)"
          fill="#fff"
        />
        <path
          id="Path_75"
          data-name="Path 75"
          d="M31.187,28.8v-6h-14v-7h12v-6h-10a80.117,80.117,0,0,0-9,6v13Z"
          transform="translate(2.818 2.711)"
          fill="#fff"
        />
        <path
          id="Path_76"
          data-name="Path 76"
          d="M31.187,28.8v-6h-14v-7h12v-6h-10a80.117,80.117,0,0,0-9,6v13Z"
          transform="translate(2.818 2.711)"
          fill="#fff"
        />
        <path
          id="Path_77"
          data-name="Path 77"
          d="M30.361,8.712c0,5.337,3.484,6.866,9,9,4.448,1.69,5,2.443,5,4v1c0,1.824-1.51,3-4,3-3.113,0-5.909-2.177-8-4l-4,5c3.426,3.2,8.04,4,12,4,6.048,0,10-3.219,10-9h0c0-4.848-2.574-6.865-8-9-4.848-2-6-3.221-6-5h0c0-1.6,1.776-3,4-3,2.4,0,4.043,1.4,6,3l4-5c-.667-.02-1.328,0-2,0-6.135,0-12.34,1.491-18,3a9.763,9.763,0,0,0,0,3Z"
          transform="translate(7.645 0.797)"
          fill="#fff"
        />
        <path
          id="Path_78"
          data-name="Path 78"
          d="M30.361,8.712c0,5.337,3.484,6.866,9,9,4.448,1.69,5,2.443,5,4v1c0,1.824-1.51,3-4,3-3.113,0-5.909-2.177-8-4l-4,5c3.426,3.2,8.04,4,12,4,6.048,0,10-3.219,10-9h0c0-4.848-2.574-6.865-8-9-4.848-2-6-3.221-6-5h0c0-1.6,1.776-3,4-3,2.4,0,4.043,1.4,6,3l4-5c-.667-.02-1.328,0-2,0-6.135,0-12.34,1.491-18,3a9.763,9.763,0,0,0,0,3Z"
          transform="translate(7.645 0.797)"
          fill="#fff"
        />
        <path
          id="Path_79"
          data-name="Path 79"
          d="M54.414,6.426v-6h-7Z"
          transform="translate(12.592 0.083)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export default FooterDetail;
